/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #222336; */
  min-height: 95vh;
  display: flex;
  justify-content:space-between;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
  padding: 16px;
  overflow-x: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-select select option {
  padding: 5px; /* Adjust the padding as needed */
}


.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #35384c;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ide-button{
 /* background-color: #595c76; */
 /* color: white; */
 padding: 6px 10px;
 border-radius: 2px;
 font-size: 16px;
 border: none;

}
.live-node{
  height: 14px;
  width: 14px;
  border-radius: 100%;
  background-color: green;
}

.notlive-node{
  height: 14px;
  width: 14px;
  border-radius: 100%;
  background-color: red;
}


.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: #35384c;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}



.App-header  li {
  margin-bottom: 6px;
}


